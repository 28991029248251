import IC_BRAND_LOGO from "../res/logo.png";
import IC_SMALL_LOGO from "../res/mobile_Logo.png";
import IC_DASHBOARD from "../res/dashboard.svg";
import IC_DEALERS from "../res/dealers.svg";
import IC_ARROW_DOWN from "../res/ic-arrow-down.svg";
import IC_MASTER_MANAGEMENT from "../res/mgmt.svg";
import IC_MASTER_MANAGEMENT_USER from "../res/user-list.svg";
import IC_MASTER_MANAGEMENT_ROLE from "../res/role-list.svg";
import IC_PERMISSIONS from "../res/permissions.svg";
import IC_DELETE from "../res/ic-delete.png";
import IC_RTO_SERVICES from "../res/rto-service.svg";
import IC_RTO_PICKUP from "../res/rto-pickup-request.svg";
import IC_RTO_CASES from "../res/rto-vehicle-cases.svg";
import IC_BACK_ARROW from "../res/ic-back-arrow.png";
import IC_RTO_CHALLAN from "../res/rto-challan.svg";
import IC_NOTIFICATION from "../res/notifications.png";
import IC_EDIT_PEN from "../res/ic-edit-pen.svg";
import IC_EDIT_PEN_WHITE from "../res/ic-edit-pen-white.png";
import IC_LOGOUT from "../res/logout.png";
import IC_PROFILE from "../res/profile.png";
import IC_SEARCH from "../res/search-icon.svg";
import IC_UIM_BOX from "../res/uim_box.png";
import IC_UIM_PIE from "../res/uim_chart-pie.png";
import IC_UIM_CIRCLES from "../res/uim_circle-layer.png";
import IC_RIGHT_ARROW from "../res/right-arrow.png";
import IC_DOWNLOAD from "../res/download.svg";
import IC_EDIT from "../res/edit.svg";
import IC_ADD from "../res/plus.svg";
import IC_EYE_OPEN_FILLED from "../res/eye_open_filled.svg";
import IC_HAMBURGER from "../res/hamburger.png";
import IC_CLOSE from "../res/close_icon.svg";
import IC_CLOSE_CIRCLE from "../res/gridicons_cross-circle.png";
import IC_PASSWORD_EYE from "../res/remove_password_eye.svg";
import IC_PASSWORD_VIEW from "../res/password-view.svg";
import IC_PASSWORD_VIEW_OFF from "../res/password-view-off.svg";
import IC_CAR_ACCESORIES from "../res/car-accesories-amico.svg";
import IC_DOC_UNVIEW from "../res/unview-doc.svg";
import IC_DOC_VIEW from "../res/view_doc.png";
import IC_DOC_DOWNLOAD from "../res/solar_download_square_bold.png";
import IC_DOC_DELETE from "../res/ion_trash_bin.png";
import IC_UPLOAD from "../res/upload.png";
import IC_ARROW_BACK from "../res/arrow_back.png";
import IC_DENIED from "../res/denied.png";
import IMG_PDF_ICON from "../res/pdf.png";
import IMG_PNG_ICON from "../res/png_icon.png";
import IC_FILTER_ICON from "../res/image_filter.svg";
import IC_PRIVACY_POLICY from "../res/privacy-policy.png";
import IC_TERMS_CONDITIONS from "../res/terms-and-conditions.png";
import IC_DEALER_PREFRENCE  from "../res/Dealer preference.png";
import IC_ADVERTISING from "../res/adversting.svg";
import IC_CAMERA from "../res/camera.svg";
import IC_FINANCIAL_SERVICES from "../res/financial-services.svg";
import IC_LOANS from "../res/loans.svg";
import IC_TABLE_EDIT from "../res/table_edit.svg";
import IC_TABLE_CROSS_CIRCLE from "../res/table_cross_circle.svg";
import IC_LETS_REMOVE_FILL from "../res/lets_remove-fill.svg";

export const R = {
  ic_brand_logo: IC_BRAND_LOGO,
  ic_preference_logo: IC_DEALER_PREFRENCE,
  ic_small_logo: IC_SMALL_LOGO,
  ic_dashboard: IC_DASHBOARD,
  ic_dealers: IC_DEALERS,
  ic_arrow_down: IC_ARROW_DOWN,
  ic_master_management: IC_MASTER_MANAGEMENT,
  ic_user_list: IC_MASTER_MANAGEMENT_USER,
  ic_role_list: IC_MASTER_MANAGEMENT_ROLE,
  ic_back_arrow: IC_BACK_ARROW,
  ic_edit_pen: IC_EDIT_PEN,
  ic_delete: IC_DELETE,
  ic_edit_pen_white: IC_EDIT_PEN_WHITE,
  ic_permissions: IC_PERMISSIONS,
  ic_rto_services: IC_RTO_SERVICES,
  ic_rto_pickup: IC_RTO_PICKUP,
  ic_rto_cases: IC_RTO_CASES,
  ic_rto_challan: IC_RTO_CHALLAN,
  ic_notification: IC_NOTIFICATION,
  ic_logout: IC_LOGOUT,
  ic_profile: IC_PROFILE,
  ic_search: IC_SEARCH,
  ic_uim_box: IC_UIM_BOX,
  ic_uim_pie: IC_UIM_PIE,
  ic_uim_circles: IC_UIM_CIRCLES,
  ic_right_arrow: IC_RIGHT_ARROW,
  ic_eye_open_filled: IC_EYE_OPEN_FILLED,
  ic_edit: IC_EDIT,
  ic_download: IC_DOWNLOAD,
  ic_add: IC_ADD,
  ic_hamburger: IC_HAMBURGER,
  ic_close: IC_CLOSE,
  ic_close_circle: IC_CLOSE_CIRCLE,
  ic_password_eye: IC_PASSWORD_EYE,
  ic_password_view: IC_PASSWORD_VIEW,
  ic_password_view_off: IC_PASSWORD_VIEW_OFF,
  ic_reset_password_screen: IC_CAR_ACCESORIES,
  ic_doc_view: IC_DOC_VIEW,
  ic_doc_unview: IC_DOC_UNVIEW,
  ic_doc_download: IC_DOC_DOWNLOAD,
  ic_doc_delete: IC_DOC_DELETE,
  ic_upload: IC_UPLOAD,
  ic_arrow_back: IC_ARROW_BACK,
  ic_denied: IC_DENIED,
  img_pdf_icon: IMG_PDF_ICON,
  img_png_icon: IMG_PNG_ICON,
  ic_filter_icon: IC_FILTER_ICON,
  ic_privacy_policy: IC_PRIVACY_POLICY,
  ic_terms_conditions: IC_TERMS_CONDITIONS,
  ic_advertising: IC_ADVERTISING,
  ic_camera: IC_CAMERA,
  ic_financial_services: IC_FINANCIAL_SERVICES,
  ic_loans: IC_LOANS,
  ic_table_edit: IC_TABLE_EDIT,
  ic_table_cross_circle: IC_TABLE_CROSS_CIRCLE,
  ic_lets_remove_fill: IC_LETS_REMOVE_FILL,
};
