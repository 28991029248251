import axios from "axios";
import axiosInstance from "axios";

import {
  API_URL,
  DEALER_URL,
  SERVICE_HISTORY_URL,
  INVENTORY_URLS,
  CSV_LEAD_URLS,
} from "../config";
import { method } from "lodash";

export const call = async (method, url, params = {}, data = {}, token) => {
  data = { ...data };
  const result = await axios({
    method,
    url: API_URL + url,
    params,
    data: { ...data },
    headers: {
      "Content-Type": "application/json",
      authorization: token ? `Bearer ${token}` : "",
    },
    responseType: "json",
  });

  return result;
};

export const customerCall = async (method, url, params = {}, data = {}) => {
  console.log("data ", data);
  const result = await axios({
    method,
    url: "http://localhost:8000/api/v1" + url,
    params,
    data,
    responseType: "json",
  });
  console.log("customer url", url);
  return result;
};

export const uploadedDocumentDealerApi = async (
  method,
  url,
  params = {},
  data = {}
) => {
  console.log("data ", data);
  const result = await axios({
    method,
    url: DEALER_URL + `admin-dealer-auth${url}`,
    params,
    data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    responseType: "json",
  });
  console.log("customer url", url);
  return result;
};

export const UpdateDealerUploadDocument = async (
  method,
  url,
  params = {},
  data = {}
) => {
  console.log("data ", data);
  const result = await axios({
    method,
    url: DEALER_URL + `admin-dealer-auth${url}`,
    params,
    data,
    headers: {
      "Content-Type": "application/json",
    },
    responseType: "json",
  });
  console.log("customer url", url);
  return result;
};

export const uploadcall = async (
  method,
  url,
  params = {},
  data = {},
  token
) => {
  data = { ...data };
  const result = await axios({
    method,
    url: INVENTORY_URLS + url,
    params,
    data: { ...data },
    headers: {
      "Content-Type": "application/json",
      //   "Accept-Language": "en",
      authorization: token ? `Bearer ${token}` : "",
    },
    // timeout: 60000,
    responseType: "json",
  });

  return result;
};

export const brancall = async (method, url, params = {}, data = {}, token) => {
  console.log("param for api call", params);
  data = { ...data };
  const result = await axios({
    method,
    url: INVENTORY_URLS + url,
    params,
    data: { ...data },
    headers: {
      "Content-Type": "application/json",
      //   "Accept-Language": "en",
      // authorization: token ? `Bearer ${token}` : "",
    },
    // timeout: 60000,
    responseType: "json",
  });

  return result;
};

export const dealerPrefrencesCall = async (method, url, data, token) => {
  try {
    const result = await axios({
      method,
      url: CSV_LEAD_URLS + url,
      data: data,
      headers: {
        "Content-Type": "application/json",
        authorization: token ? `Bearer ${token}` : "",
      },
      responseType: "json",
    });
    console.log("reslt ", result)
    return result.data;
  } catch (error) {
    console.error("Error saving CSV list:", error);
    throw error;
  }
};


export const getDealerCallPrefrence = async (method, url, token) => {
  try {
    const result = await axios({
      method,
      url: CSV_LEAD_URLS + url,
      headers: {
        "Content-Type": "application/json",
        authorization: token ? `Bearer ${token}` : "",
      },
      responseType: "json",
    });
    // console.log("url ", url);
    // console.log("reslt for get pre", result.data)
    return result.data;
  } catch (error) {
    console.error("Error saving CSV list:", error);
    throw error;
  }
};


export const dealerCall = async (
  method,
  url,
  params = {},
  data = {},
  token
) => {
  data = { ...data };
  const result = await axios({
    method,
    url: DEALER_URL + url,
    params,
    data: { ...data },
    headers: {
      "Content-Type": "application/json",
      //   "Accept-Language": "en",
      authorization: token ? `Bearer ${token}` : "",
    },
    // timeout: 60000,
    responseType: "json",
  });

  return result;
};

export const serviceHistoryCall = async (
  method,
  url,
  params = {},
  data = {},
  token
) => {
  data = { ...data };
  const result = await axios({
    method,
    url: SERVICE_HISTORY_URL + url,
    params,
    data: { ...data },
    headers: {
      "Content-Type": "application/json",
      //   "Accept-Language": "en",
      authorization: token ? `Bearer ${token}` : "",
    },
    // timeout: 60000,
    responseType: "json",
  });

  return result;
};

export const inventoryCreate = async (
  method,
  url,
  params = {},
  data,
  token
) => {

  try {
    const result = await axiosInstance({
      method,
      url: INVENTORY_URLS + url,
      params,
      data,
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
      },
    });
    return result.data;
  } catch (error) {
    if (error.response) {
    } else if (error.request) {
      console.error("Request data:", error.request);
    } else {
      console.error("Error message:", error.message);
    }
    console.error("Error config:", error.config);

    throw error;
  }
};

export const saveCsvList = async (method, url, formData, token) => {
  try {
    const result = await axios({
      method,
      url: CSV_LEAD_URLS + url,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        authorization: token ? `Bearer ${token}` : "",
      },
      responseType: "json",
    });
    return result.data;
  } catch (error) {
    console.error("Error saving CSV list:", error);
    throw error;
  }
};


export const multiBrandcall = async (method, url, data ) => {
  console.log("param for api call", data);
  const result = await axios({
    method,
    url: INVENTORY_URLS + url,
    data: data ,
    headers: {
      "Content-Type": "application/json",
      //   "Accept-Language": "en",
      // authorization: token ? `Bearer ${token}` : "",
    },
    // timeout: 60000,
    responseType: "json",
  });

  return result;
};

export const saveCsvFileData = async (method, url, data, token) => {
  try {
    const result = await axios({
      method,
      url: CSV_LEAD_URLS + url,
      data: data,
      headers: {
        "Content-Type": "application/json",
        authorization: token ? `Bearer ${token}` : "",
      },
      responseType: "json",
    });
    return result.data;
  } catch (error) {
    console.error("Error saving CSV list:", error);
    throw error;
  }
};

export const updateCsvStatus = async (method, url, token) => {
  try {
    const result = await axios({
      method,
      url: CSV_LEAD_URLS + url,
      headers: {
        "Content-Type": "application/json",
        authorization: token ? `Bearer ${token}` : "",
      },
      responseType: "json",
    });
    return result.data;
  } catch (error) {
    console.error("Error saving CSV list:", error);
    throw error;
  }
};



export const carList = async (method, url, params = {}, data = {}, token) => {
  data = { ...data };
  const result = await axios({
    method,
    url: INVENTORY_URLS + url,
    params,
    data: { ...data },
    headers: {
      "Content-Type": "application/json",
      authorization: token ? `Bearer ${token}` : "",
    },
    responseType: "json",
  });

  return result;
};

export const csvList = async (method, url, params = {}, data = {}, token) => {
  data = { ...data };
  const result = await axios({
    method,
    url: CSV_LEAD_URLS + url,
    params,
    data: { ...data },
    headers: {
      "Content-Type": "application/json",
      authorization: token ? `Bearer ${token}` : "",
    },
    responseType: "json",
  });
  return result;
};



export const templateCall = async (method, url) => {
  const result = await axios({
    method,
    url: DEALER_URL + 'admin-dealer-auth/' + url,
    headers: {
      "Content-Type": "application/json",
    },
    responseType: "json",
  });
  return result;
};

export const uplodTemplate = async (method, url, formData) => {
  const result = await axios({
    method,
    url: DEALER_URL + 'admin-dealer-auth/' + url,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: formData,
    responseType: "json",
  });
  return result;
};

export const templateDetails = async (method, url, params = {}) => {
  const result = await axios({
    method,
    url: DEALER_URL + 'admin-dealer-auth/' + url,
    params,
    headers: {
      "Content-Type": "application/json",
    },
    responseType: "json",
  });
  return result;
};

export const customerCalls = async (
  method,
  url,
  params = {},
  data = {},
  token
) => {
  const result = await axios({
    method,
    url: SERVICE_HISTORY_URL +"admin/buyer-lead/"+ url,
    params,
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "application/json",
    },
    data: { ...data },
    responseType: "json",
  });
  return result;
};


export const preferenceCall = async (
  method,
  url,
  params,
  token
) => {
  const fullUrl = `${SERVICE_HISTORY_URL}admin/buyer-lead/${url}/${params}`;
  const result = await axios({
    method,
    url: fullUrl,
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "application/json",
    },
    responseType: "json",
  });
  return result;
};

export const addManageStore = async (method, url, data, params = {}) => {
  const config = {
    method,
    url: DEALER_URL + 'admin-dealer-auth/' + url,
    params,
    headers: {
      "Content-Type": "application/json",
    },
    responseType: "json",
  };

  // Only add the `data` field if the method is not GET
  if (method.toLowerCase() !== 'get') {
    config.data = data;  // Attach data to POST, PUT, etc.
  } else {
    config.params = { ...params, ...data };  // Attach data as query params for GET
  }

  const result = await axios(config);
  return result;
};
